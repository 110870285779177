import React, { useEffect, useRef, useState } from 'react'
import Navbar from '../Navbar/Navbar'
import { useAppContext } from '../../context/AppContext'
import { Button, Card, Col, message, Row, Skeleton, Space, Table, Tag } from 'antd'
import { IdcardOutlined } from "@ant-design/icons"
import { CapitaliceStrings } from "../../utils/capitaliceStrings"
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
function ExpirationsWrapper() {
    const { getExpirations, monthlyExp, expiredFiles } = useAppContext()
    const [gettingExp, setGettingExp] = useState(false)
    const navigate = useNavigate()


    const alreadyGet = useRef(false)
    useEffect(() => {
        if (!alreadyGet.current) {
            (async () => {
                alreadyGet.current = true

                const hiddenMessage = message.loading("Un segundo...", 0)
                setGettingExp(true)
                await getExpirations()
                setGettingExp(false)
                hiddenMessage()
            })()
        }
    }, [])


    useEffect(() => {
        console.log("Vencimientos del mes: ", monthlyExp)
        console.log("Deudas vencidas: ", expiredFiles)
    }, [monthlyExp, expiredFiles])


    const tableColumns1 = [
        {
            title: "Cliente",
            render: (_, record) => (
                <p>{CapitaliceStrings(record.cliente)}</p>
            )

        },
        {
            title: "Vencimiento en",
            render: (_, record) => (
                <React.Fragment>
                    <Space direction='vertical'>
                        <Space>
                            <Tag color='orange' style={{ fontSize: ".9rem" }}>{record.diasRestantes} dia/s</Tag>
                            Vence el: <Tag color='blue' style={{ fontSize: ".9rem" }} >{dayjs(record.fechaVencimiento).format("DD-MM-YYYY")}</Tag>
                        </Space>
                        <Button
                            icon={<IdcardOutlined />}
                            type='primary'
                            onClick={() => navigate(`/clientes/cuentas-corrientes/${record.deudaClienteID}`)}
                        >Ir al fichero
                        </Button>
                    </Space>

                </React.Fragment>
            )
        }
    ]

    const tableColumns2 = [
        {
            title: "Vencido el",
            render: (_, record) => (
                <Space>
                    <Tag color='brown'>{dayjs(record.fechaVencimiento).format("DD-MM-YYYY")}</Tag>
                    <Tag color='red'>Hace {record.diasVencidos} día/s</Tag>
                </Space>
            )
        },
        {
            title: "Cliente",
            render: (_, record) => (
                <Space direction='vertical'>
                    <p>{CapitaliceStrings(record.cliente)}</p>
                    <Button
                        icon={<IdcardOutlined />}
                        type='primary'
                        onClick={() => navigate(`/clientes/cuentas-corrientes/${record.id_deuda_cliente}?branchId=${record.id_punto_venta}`)}
                    >Ir al fichero
                    </Button>
                </Space>
            )
        }
    ]

    return (
        <>
            <Navbar />
            <div className='container-wrapper'>
                <Row gutter={[16, 16]}>
                    <Col xs={24} lg={12}>
                        {gettingExp
                            ? <Skeleton active />
                            : <Card title="Próximos vencimientos">
                                <Table
                                    columns={tableColumns1}
                                    bordered
                                    dataSource={monthlyExp}
                                    scroll={{x:600}}
                                />
                            </Card>
                        }
                    </Col>
                    <Col xs={24} lg={12}>
                        {gettingExp
                            ? <Skeleton active />
                            : <Card title="Ficheros vencidos">
                                <Table
                                    bordered
                                    columns={tableColumns2}
                                    dataSource={expiredFiles}
                                    scroll={{x:600}}
                                />
                            </Card>
                        }
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default ExpirationsWrapper