import React, { useEffect, useState } from 'react'
import { Button, Form, Input, message, notification } from 'antd'
import { useAppContext } from '../../../context/AppContext'
import { baseUrl } from '../../../config'

function CreateBranch({ closeModal, editing, selectedBranch }) {
    const [form] = Form.useForm()

    const [creating, setCreating] = useState(false)

    const { setAppData } = useAppContext()

    useEffect(() => {
        if (editing) {
            form.setFieldsValue({
                branchName: selectedBranch.punto_venta
            });
        }
    }, [editing, selectedBranch])

    const onFinish = async (values) => {

        const { branchName } = values

        setCreating(true);
        editing ? await editBranch(branchName) : await saveBranch(branchName)
        setCreating(false);
        closeModal()
    }

    const editBranch = async (branchName, oldBranchId = selectedBranch.id) => {
        let data = {}
        try {
            const response = await fetch(`${baseUrl.api}/update-branch/${oldBranchId}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ branchName })
            });

            if (response.ok) {
                data = await response.json()
                const updBranch = data.updBranch
                message.success(`${data.msg}`, 2)
                setAppData((prevData) => ({
                    ...prevData,
                    puntosVenta: prevData.puntosVenta.map((pventa) =>
                        pventa.id === updBranch.id
                            ? { ...pventa, punto_venta: updBranch.punto_venta }
                            : pventa
                    ),
                }));
            } else {
                throw new Error("Error al procesar la solicitud, espere unos segundos e intente nuevamente.")
            }
        } catch (error) {
            console.log(error)
            notification.error({
                message: "No se pudo actualizar la sucursal",
                description: error.message || "Error de conexión, verifique su red e intente nuevamente.",
                pauseOnHover: false,
                showProgress: true,
                duration: 4
            })
        }
    }

    const saveBranch = async (branchName) => {
        try {
            const response = await fetch(`${baseUrl.api}/create-branch`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ branchName })
            });

            const data = await response.json();

            if (!response.ok) throw new Error(data.msg);

            notification.success({
                message: "Sucursal guardada!",
                duration: 2,
                showProgress: true,
                pauseOnHover: false
            });

            console.log("Respuesta: ", data)

            const newBranch = {
                id: data.newBranch.id,
                punto_venta: data.newBranch.punto_venta
            };


            setAppData((prevData) => ({
                ...prevData,
                puntosVenta: [...prevData.puntosVenta, newBranch]
            }));

            closeModal()
        } catch (error) {
            console.error(error);
            notification.error({
                message: "Error al guardar la sucursal",
                duration: 4,
                description: error.message || "Error de red, verifique su conexión e intente nuevamente",
                pauseOnHover: false,
            });
        }
    }
    return (
        <Form
            name='branchForm'
            layout='vertical'
            onFinish={onFinish}
            form={form}
        >
            <Form.Item
                name={"branchName"}
                label="Nombre de la sucursal: "
                rules={[
                    { required: true, message: "Este campo no puede estar vacío" }
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item>
                <Button htmlType='submit' loading={creating}>Guardar</Button>
            </Form.Item>
        </Form>
    )
}

export default CreateBranch