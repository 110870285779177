import { Button, Form, Input, Space } from 'antd'
import React, { useEffect } from 'react'
import { useAppContext } from '../../../context/AppContext'

function CreateClient() {
    const {
        saveClient,
        setEditingClient,
        editingClient,
        setSelectedClient,
        selectedClient,
        editClient
    } = useAppContext()

    const [form] = Form.useForm()

    const onFinish = async (values) => {
        const newClient = {
            direccion: values.clientAdress ?? "",
            dni: values.clientDNI,
            email: values.clientEmail ?? "",
            nombre_completo: values.clientName,
            telefono: values.clientPhone ?? ""
        }
        const isSaved = editingClient
            ? await editClient(newClient, selectedClient.id)
            : await saveClient(newClient)
        if (isSaved) form.resetFields()
    }

    const cancelEdit = () => {
        setEditingClient(false)
        setSelectedClient(null)
    }

    useEffect(() => {
        if (editingClient && selectedClient) {
            form.setFieldsValue({
                clientName: selectedClient.nombre_completo,
                clientDNI: selectedClient.dni,
                clientAdress: selectedClient.direccion ?? "",
                clientPhone: selectedClient.telefono ?? "",
                clientEmail: selectedClient.email ?? ""
            })
        } else {
            form.resetFields()
        }
    }, [editingClient, selectedClient])

    useEffect(() => {
        console.log(selectedClient)
    }, [selectedClient])
    return (
        <>
            <Form
                name='clientForm'
                onFinish={onFinish}
                layout='vertical'
                form={form}

            >
                <Form.Item
                    name="clientName"
                    label="Nombre Completo"
                    rules={[
                        { required: true, message: "El nombre es requerido" }
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="clientDNI"
                    label="DNI"
                    rules={[
                        {
                            required: true, validator: (_, value) => {
                                if (value.trim().length < 5) {
                                    return Promise.reject(new Error("El DNI no es válido"))
                                }
                                return Promise.resolve()
                            }
                        }
                    ]}
                >
                    <Input />
                </Form.Item>


                <Form.Item
                    name="clientAdress"
                    label="Dirección"

                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="clientPhone"
                    label="Teléfono"
                    rules={[{
                        validator: (_, value) => {
                            const regex = /^\+?[0-9]*$/
                            if (value && !regex.test(value)) {
                                return Promise.reject(new Error("Número de teléfono no válido"))
                            }
                            return Promise.resolve()
                        }
                    }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="clientEmail"
                    label="Correo"
                    rules={[{ type: "email", message: "Correo no válido" }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item>
                    <Space>
                        <button className='btn__save-client' type='submit'>{editingClient ? "Actualizar cliente" : "Guardar cliente"}</button>
                        {editingClient && <Button type="primary" danger onClick={cancelEdit}>Cancelar</Button>}
                    </Space>
                </Form.Item>
            </Form>
        </>
    )
}

export default CreateClient