import React, { useEffect, useRef, useState } from 'react';
import Sidebar from '../SideBar/Sidebar';
import './currentAccounts.css';
import MoneIcon from '../../assets/MoneIcon';
import DeliverIcon from '../../assets/DeliverIcon';
import AddDebts from './Crear Deudas/AddDebts';
import ViewDebts from './VisualizarDeudas/ViewDebts';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { RollbackOutlined } from '@ant-design/icons';
import { useAppContext } from '../../context/AppContext';
import { CapitaliceStrings } from '../../utils/capitaliceStrings';

import AddDelivers from './Crear Entregas/AddDelivers';
import ViewDelivers from './Visualizar entregas/ViewDelivers';
import { baseUrl } from '../../config';
import { message, notification } from 'antd';

function CurrentAccounts() {
    const { appData,
        authUserInfo,
        getClientFile,
        clientFile,
        totalDebts,
        totalDelivers,
        subTotalFile,
        gettingFile,
        entregas,
        setTotalDebts,
        setTotalDelivers,
        setSubTotalFile
        , setClientId
    } = useAppContext();
    const clientes = appData.clientes || [];

    
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const { clientId } = useParams();
    const branchId = queryParams.get("branchId")
    setClientId(clientId)

    const alreadyGetted = useRef(false);

    useEffect(() => {
        if (!alreadyGetted.current && authUserInfo.id_punto_venta) {
            alreadyGetted.current = true;
            (async () => {
                await getClientFile(branchId || undefined, clientId);
            })()
        }
    }, [authUserInfo]);

    const cancelDebts = async (branchId) => {
        const debts = clientFile?.deudas || []
        const delivers = clientFile?.entregas || []
        const formData = new FormData();

        debts.forEach(debt => {
            formData.append("debts", JSON.stringify(debt));
        });

        delivers.forEach(deliver => {
            formData.append("delivers", JSON.stringify(deliver));
        });

        try {
            const response = await fetch(`${baseUrl.api}/cancel-debts/${clientId}?branchId=${branchId}`, {
                method: "POST",
                body: formData,
            });

            let data;
            const contentType = response.headers.get("content-type");

            if (contentType && contentType.includes("application/json")) {
                data = await response.json();
            } else {
                data = { msg: "Error al comunicarse con el servidor, espere unos segundos e intente nuevamente." };
            }

            if (!response.ok) {
                throw new Error(data.msg);
            }

            message.success("Deudas canceladas!");
            await getClientFile()
        } catch (error) {
            console.error(error);
            notification.error({
                message: "Error al cancelar la deuda",
                description: error.message || "Error de conexión, verifique su red e intente nuevamente.",
                duration: 5,
                pauseOnHover: false,
                showProgress: true,
            });
        }

    }

    useEffect(() => {
        if (clientFile.deudas && clientFile.deudas.length > 0) {
            const totalDebts = clientFile.deudas.reduce((acc, curr) => {
                return acc + parseFloat(curr.precio) * parseInt(curr.cantidad);
            }, 0);
            setTotalDebts(totalDebts);
        }
    }, [clientFile]);

    useEffect(() => {
        if (clientFile.entregas && clientFile.entregas.length > 0) {
            const totalDelivers = clientFile.entregas.reduce((acc, curr) => {
                return acc + parseFloat(curr.monto);
            }, 0);
            setTotalDelivers(totalDelivers);
        } else {
            setTotalDelivers(0)
        }
    }, [clientFile]);

    useEffect(() => {
        if (totalDebts > 0) {
            setSubTotalFile(totalDebts - totalDelivers);
        } else {
            setSubTotalFile(totalDebts)
        }
    }, [totalDebts, totalDelivers, clientFile]);

    const [selectedLink, setSelectedLink] = useState(1);
    const navigate = useNavigate();
    const componentLinks = [
        {
            name: 'Deudas',
            key: 1,
            icon: <MoneIcon />,
        },
        {
            name: 'Entregas',
            key: 2,
            icon: <DeliverIcon />,
        },
        {
            name: 'Retroceder',
            key: 3,
            icon: <RollbackOutlined />,
        },
    ];

    const handleLinkSelect = (referId) => {
        setSelectedLink(referId);
    };

    const renderTable = () => {
        switch (selectedLink) {
            case 1:
                return (
                    <div className="debts__container">
                        <ViewDebts clientId={clientId} getClientFile={getClientFile} gettingFile={gettingFile} hasDelivers={Boolean(totalDelivers)} />
                        <AddDebts clientId={clientId} getClientFile={getClientFile} gettingFile={gettingFile} />
                    </div>
                );
            case 2:
                return (
                    <div className="debts__container">
                        <ViewDelivers clientId={clientId} getClientFile={getClientFile} gettingFile={gettingFile} delivers={entregas} />
                        <AddDelivers clientId={clientId} getClientFile={getClientFile} gettingFile={gettingFile} subTotalFile={subTotalFile} hasDebts={Boolean(totalDebts)}/>
                    </div>
                );
            case 3:
                navigate('/clientes');
                break;
            default:
                return <p>Seleccione una opción del menú</p>;
        }
    };

    const clientName = clientes.find((cliente) => cliente.id === clientId)?.nombre_completo || 'Cliente no encontrado';
    return (
        <div className="container-wrapper">
            <div className="current-accounts__wrapper">

                <Sidebar
                    componentTitle={'Cuentas Corrientes'}
                    componentSubtitle={CapitaliceStrings(clientName)}
                    references={componentLinks}
                    onLinkSelect={handleLinkSelect}
                    RetryOperation={getClientFile}
                    totalFile={subTotalFile}
                    gettingFile={gettingFile}
                    getClientFile={getClientFile}
                    cancelDebts={cancelDebts}
                    canCancel={Boolean(totalDebts) && Boolean(totalDelivers)}
                />
                {renderTable()}
            </div>
        </div>
    );
}

export default CurrentAccounts;
