import React, { useEffect, useState } from 'react';
import Navbar from '../Navbar/Navbar';
import { useAppContext } from '../../context/AppContext';
import { Modal, Select, Space, Switch, Table } from 'antd';
import { InfoCircleOutlined, MailOutlined, ShopOutlined, UserOutlined } from "@ant-design/icons";
import "./dashboard.css";
import AdminLogo from '../../assets/AdminLogo';
import AccessIcon from "../../assets/AccessIcon";
import Sidebar from '../SideBar/Sidebar';
import UsersIcon from "../../assets/UsersIcon"
import SalesIcon from "../../assets/SalesIcon"
import ExpirationIcon from "../../assets/ExpirationIcon"
import SucursalesWrapper from '../Sucursales/SucursalesWrapper';
const { Option } = Select;
function Dashboard() {
  const { appData, assignBusiness, changeAccessUser } = useAppContext();
  const usuarios = appData?.usuarios || [];
  const sucursales = appData?.puntosVenta || [];

  const [selectedLink, setSelectedLink] = useState(1);

  const onChangeSucursales = (branchId, userid) => {
    assignBusiness(branchId, userid);
  };


  const usersTable = [
    {

      render: (_, record) => (
        <>
          <picture className='userimage-wrapper'>
            <img src={record.userimage} alt="" />
          </picture>
        </>
      )
    },
    {
      title: "Usuario",
      width: 150,
      render: (_, record) => (
        <>
          <p><UserOutlined /> {record.userfullname}</p>
          <p><MailOutlined /> {record.useremail}</p>
        </>
      )
    }, {
      title: "Detalles",
      width: 650,
      render: (_, record) => (
        <>
          <ul>
            <li><strong><AdminLogo /> Administrador: </strong>{record.administrador ? "Si" : "No"}</li>
            <li><strong><InfoCircleOutlined /> Permisos restringidos: </strong>{record.administrador ? "Control total" : "Si"}</li>
            <li><strong><AccessIcon /> Acceso autorizado: </strong>
              <Switch disabled={record.administrador} unCheckedChildren="No" checkedChildren="Si" value={record.autorizado} onChange={() => changeAccessUser(record.userid)}>
              </Switch>
            </li>
          </ul>
        </>
      ),

    },
    {
      title: "Sucursal",
      render: (_, record) => (
        <>
          <Space direction='vertical'>
            <strong><ShopOutlined /> Sucursal asignada: {record.punto_venta ?? "Ninguna"}</strong>
            <Select onChange={(val) => onChangeSucursales(val, record.userid)} style={{ width: "100%" }} value={record.id_punto_venta}>
              {sucursales && sucursales.map((branch, index) => (
                <Option key={index} value={branch.id}>{branch.punto_venta}</Option>
              ))}
            </Select>
          </Space>
        </>
      )
    },

  ];

  const componentLinks = [
    {
      name: "Usuarios",
      key: 1,
      icon: <UsersIcon />
    },
    {
      name: "Pagos",
      key: 2,
      icon: <SalesIcon />
    },
    {
      name: "Sucursales",
      key: 4,
      icon: <ShopOutlined />
    }
  ];

  const handleLinkSelect = (referId) => {
    setSelectedLink(referId);
  };

  const renderTable = () => {
    switch (selectedLink) {
      case 1:
        return (
          <Table
            dataSource={usuarios}
            columns={usersTable}
            scroll={{ x: 700 }}
            className='users__table'
            pagination={{ pageSize: 5 }}
            rowKey={(rowKey) => rowKey.id}
          />
        );
      case 4:
        return (
          <SucursalesWrapper />
        )
      default:
        return <p>Seleccione una opción del menú</p>;
    }
  };

  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    const resize = () => setWidth(window.innerWidth)
    window.addEventListener("resize", resize)

    return () => window.removeEventListener("resize", resize)
  }, [])

  return (
    <>
      <Navbar />
      <div className='container-wrapper'>
        <div className="dashboard__wrapper">
          <Sidebar
            componentTitle={"Dashboard"}
            references={componentLinks}
            onLinkSelect={handleLinkSelect}
          />
          {renderTable()}
        </div>
      </div>
    </>
  );
}

export default Dashboard;
