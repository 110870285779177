import React, { useEffect, useState } from 'react'
import "./viewDelivers.css"
import { Button, Card, message, Modal, notification, Popconfirm, Skeleton, Space, Table } from 'antd'
import dayjs from 'dayjs'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { baseUrl } from '../../../config'
import AddDelivers from '../Crear Entregas/AddDelivers'
import { useAppContext } from '../../../context/AppContext'
function ViewDelivers({getClientFile, gettingFile }) {
  const [delivers, setDelivers] = useState([])

  const { clientFile } = useAppContext()

  useEffect(()=>{
    clientFile.entregas ? setDelivers(clientFile.entregas) : setDelivers([])
  },[clientFile])

  const [deletingDeliver, setDeletingDeliver] = useState(false)

  const deleteDeliver = async (deliverId) => {
    setDeletingDeliver(true)
    try {
      const response = await fetch(`${baseUrl.api}/delete-deliver/${deliverId}`, {
        method: "DELETE"
      });

      const data = await response.json()
      if (!response.ok) throw new Error(data.message)
      await getClientFile()
      message.success("Entrega eliminada", 2)
    } catch (error) {
      console.log(error)
      notification.error({
        message: "La entrega no pudo ser eliminada.",
        description: error.message || "Error de conexión, espere unos segundos e intente nuevamente más tarde.",
        duration: 4,
        showProgress: true,
        pauseOnHover: false
      })
    } finally {
      setDeletingDeliver(false)
    }
  }

  const [editingDeliver, setEditingDeliver] = useState(false)
  const [selectedDeliver, setSelectedDeliver] = useState({})
  const [openEditModal, setOpenEditModal] = useState(false)
  const handleEditDeliver = (deliverId) => {
    setSelectedDeliver(delivers.find(deliver => deliver.id === deliverId))
    setEditingDeliver(true)
    setOpenEditModal(true)

  }

  const columns = [
    {
      title: "Fecha de entrega",
      render: (_, record) => (
        <>
          <em>{dayjs(record.fecha_entrega).format("DD/MM/YYYY")}</em>
        </>
      )
    },
    {
      title: "Monto",
      render: (_, record) => (
        <>
          <p>{parseFloat(record.monto).toLocaleString("es-AR", { style: "currency", currency: "ARS" })}</p>
        </>
      )
    },
    {
      render: (_, record) => (
        <Space direction='vertical'>
          <Button type='primary' icon={<EditOutlined />} onClick={() => handleEditDeliver(record.id)}></Button>
          <Popconfirm
            title="¿Seguro que desea eliminar esta entrega?"
            description={`Si esta entrega hizo que una deuda pase a estar al día, \n tendrá que esperar 30 minutos para que los cambios vuelvan a la normalidad, y es posible que el saldo total quede en negativo.`}
            okButtonProps={[
              { loading: deletingDeliver }
            ]}
            onConfirm={() => deleteDeliver(record.id)}
          >
            <Button type='primary' danger icon={<DeleteOutlined />}></Button>
          </Popconfirm>
        </Space>
      )
    }
  ]
  return (
    <>
      <Card className='cards__debts' title="Ver entregas">
        {gettingFile ? <Skeleton active /> : <Table
          columns={columns}
          dataSource={delivers}
          rowKey={(row) => row.id}
          scroll={{ x: 500 }}
        />}
      </Card>

      {openEditModal && (
        <Modal
          open={true}
          onCancel={() => setOpenEditModal(false)}
          footer={[]}
          title="Editar entrega de dinero."

        >
          <AddDelivers getClientFile={getClientFile} editingDeliver={editingDeliver} selectedDeliver={selectedDeliver} closeModal={() => setOpenEditModal(false)} />
        </Modal>
      )}
    </>
  )
}

export default ViewDelivers