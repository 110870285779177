import React from 'react'
import { useAppContext } from '../../../context/AppContext'
import { Table } from 'antd'
import { CapitaliceStrings } from '../../../utils/capitaliceStrings'
import "./manageClients.css"
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
function ManageClients() {
    const { 
        appData,
        setEditingClient,
        setSelectedClient,
    } = useAppContext()

    const clients = appData.clientes ?? []


    const handleEditClient = (clientId) => {
        setEditingClient(true)
        setSelectedClient(clients.find(client => client.id === clientId))
    }

    const clientsTable = [
        {
            title: "Cliente",
            render: (_, record) => (
                <>
                    <p><strong>Nombre: </strong>{CapitaliceStrings(record.nombre_completo)}</p>
                    <p><strong>DNI: </strong>{record.dni}</p>

                </>
            )
        }, {
            title: "Datos adicionales",
            render: (_, record) => (
                <>
                    <p><strong>Dirección: </strong>{record.direccion || "N/A"}</p>
                    <p><strong>Teléfono: </strong>{record.telefono || "N/A"}</p>
                    <p><strong>Email: </strong>{record.email || "N/A"}</p>
                </>
            )
        },
        {
            width: 180,
            render: (_, record) => (
                <div className='client__btn-wrapper'>
                    <button className='client-btn' disabled><DeleteOutlined /> Eliminar cliente</button>
                    <button className='client-btn' onClick={() => handleEditClient(record.id)}><EditOutlined /> Editar datos</button>
                </div>
            )
        }
    ]
    return (
        <Table
            columns={clientsTable}
            dataSource={clients}
            rowKey={(row) => row.id}
            scroll={{ x: 500 }}
            pagination={{ pageSize: 6 }}
        >

        </Table>
    )
}

export default ManageClients