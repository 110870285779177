import React, { useEffect, useState } from 'react'
import "./addDelivers.css"
import { Button, Card, DatePicker, Form, Input, notification } from 'antd'
import dayjs from 'dayjs'
import { useAppContext } from '../../../context/AppContext'
function AddDelivers({
  clientId, 
  getClientFile, 
  gettingFile, 
  subTotalFile, 
  editingDeliver, 
  selectedDeliver,
  closeModal,
  hasDebts
}) {
  const [deliverDate, setDeliverDate] = useState(dayjs())

  const [form] = Form.useForm()

  const { saveDeliver, editDeliver } = useAppContext()

  const onFinish = async(values) => {
    const {deliverDate, deliverAmount} = values
    if(!dayjs(deliverDate).isValid() || !deliverAmount){
      notification.error({
        message: "Uno de los campos esta incompleto o inválido",
        duration: 3,
        showProgress:true,
        pauseOnHover: false
      });
      return;
    }
    const formData = new FormData()
    for (const i in values) {
      formData.append(i, values[i])
    }

    const result = editingDeliver ? await editDeliver(formData, selectedDeliver.id) : await saveDeliver(formData, clientId)
    if (result) {
      console.log("Llamando a GetClientFile")
      await getClientFile()
      form.resetFields()
    }

    if(editingDeliver) closeModal()
  }

  const handleDateChange = (date) =>{
    setDeliverDate(date)
  }

  useEffect(()=>{
    if (editingDeliver && selectedDeliver) {
        form.setFieldsValue({
          deliverAmount: selectedDeliver.monto,
        })
        setDeliverDate(dayjs(selectedDeliver.fecha_entrega))
    }
  },[editingDeliver, selectedDeliver])

  return (
    <Card className={editingDeliver ? "" : 'cards__debts'} title={editingDeliver ? "" : "Añadir entregas"}>
      <Form
      name='deliversForm'
      onFinish={onFinish}
      form={form}
      layout='vertical'
      initialValues={{
        deliverDate: deliverDate
      }}
      >
        <Form.Item
          name={"deliverAmount"}
          label={editingDeliver ? "Ingresa el nuevo monto" : `Ingrese un monto entre $0 y ${parseFloat(subTotalFile).toLocaleString("es-AR",{style: "currency", currency: "ARS"})}`}
          rules={[
            {required: true, message: "Este campo es obligatorio"},
            {validator: (_,value) => {
              if(isNaN(parseFloat(value))) return Promise.reject("El campo solo puede contener números mayores a 0")
              if(!editingDeliver && parseFloat(value) > subTotalFile) return Promise.reject(`El monto de entrega no puede ser mayor a ${parseFloat(subTotalFile).toLocaleString("es-AR",{style: "currency", currency: "ARS"})}`)
              return Promise.resolve()
            }}
          ]}
        >
          <Input placeholder='Ingresa aqui el valor de la entrega'/>
        </Form.Item>

        <Form.Item
          name={"deliverDate"}
          label="Fecha de entrega"
          rules={[
            {validator: ()=>{
              if (!dayjs(deliverDate).isValid) {
                return Promise.reject()
              }
              return Promise.resolve()
            }},
            {required: true, message: "La fecha no es válida"}
          ]}
        >
          <DatePicker id='deliverDate' onChange={handleDateChange}/>
        </Form.Item>

        <Form.Item>
          <Button htmlType='submit' disabled={gettingFile || !hasDebts} loading={gettingFile}>{editingDeliver ? "Actualizar" : "Guardar"} entrega</Button>
        </Form.Item>
      </Form>
    </Card>
  )
}

export default AddDelivers