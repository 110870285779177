import React, { useState } from 'react'
import Navbar from '../Navbar/Navbar'
import { Card, Col, Row, Space, Table } from 'antd';
import { useAppContext } from '../../context/AppContext';
import Sidebar from '../SideBar/Sidebar';
import "./clientsmanager.css"
import CreateClient from './Crear Clientes/CreateClient';
import ManageClients from "./Gestionar Clientes/ManageClients"
import { CapitaliceStrings } from '../../utils/capitaliceStrings';
import { IdcardFilled, IdcardOutlined } from '@ant-design/icons';
import UsersIcon from "../../assets/UsersIcon"
import { Route, Routes, useNavigate } from 'react-router-dom';
import CurrentAccounts from '../CuentasCorrientes/CurrentAccounts';
function ClientsWrapper() {
    const { appData } = useAppContext()
    const clientes = appData?.clientes ?? []
    const [selectedLink, setSelectedLink] = useState(1);
    const navigate = useNavigate()
    const componentLinks = [
        {
            name: "Buscar Fichero",
            key: 1,
            icon: <IdcardOutlined style={{ fontSize: 20 }} />
        },
        {
            name: "Adm. Clientes",
            key: 2,
            icon: <UsersIcon />
        }

    ];

    const handleLinkSelect = (referId) => {
        setSelectedLink(referId);
    };

    const showClientsTable = [
        {
            title: "Cliente",
            width: 350,
            render: (_, record) => (
                <Space direction='vertical'>
                    <p><strong>Nombre: </strong>{CapitaliceStrings(record.nombre_completo)}</p>
                    <p><strong>DNI: </strong>{record.dni}</p>
                </Space>
            )
        }, {
            render: (_, record) => (
                <>
                    <button className='client__btn-file' onClick={()=> navigate(`cuentas-corrientes/${record.id}`)}><IdcardFilled /> Ver Fichero</button>
                </>
            )
        }
    ]
    const renderTable = () => {
        switch (selectedLink) {
            case 1:
                return (
                    <Table
                        scroll={{ x: 500 }}
                        className='users__table'
                        pagination={{ pageSize: 10 }}
                        rowKey={(rowKey) => rowKey.id}
                        dataSource={clientes}
                        columns={showClientsTable}

                    />
                )
            case 2:
                return (
                    <Card style={{ width: "100%" }} title="Gestionar clientes">
                        <Row gutter={[16, 16]}>
                            <Col xs={24} md={24} lg={10}>
                                <CreateClient />
                            </Col>
                            <Col xs={24} md={24} lg={14}>
                                <ManageClients />
                            </Col>
                        </Row>
                    </Card>
                );
            default:
                return <p>Seleccione una opción del menú</p>;
        }
    };

    return (
        <>
            <Navbar />
            <div className='container-wrapper'>
                <Routes>
                    <Route path='/' element={
                        <>
                            <div className="client__wrapper">
                                <Sidebar
                                    componentTitle={"Clientes"}
                                    references={componentLinks}
                                    onLinkSelect={handleLinkSelect}
                                />
                                {renderTable()}
                            </div>
                        </>
                    } />
                    <Route path='cuentas-corrientes/:clientId' element={
                        <>
                            <CurrentAccounts/>
                        </>
                    }/>
                </Routes>
            </div>
        </>

    )
}

export default ClientsWrapper