import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Card, message, Modal, notification, Popconfirm, Skeleton, Space, Table, Tag } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { baseUrl } from '../../../config';
import AddDebts from '../Crear Deudas/AddDebts';
import { useAppContext } from '../../../context/AppContext';

function ViewDebts({ gettingFile, hasDelivers, clientId }) {
    const { appData, getClientFile, clientFile } = useAppContext();
    const [deudasAgrupadas, setDeudasAgrupadas] = useState([]);

    useEffect(() => {
        const debts = clientFile.deudas ?? []
        const agrupadas = debts?.reduce((acc, producto) => {
            const fechaCompra = producto.fecha_compra.split("T")[0];
            if (!acc[fechaCompra]) acc[fechaCompra] = [];
            acc[fechaCompra].push(producto);
            return acc;
        }, {});

        setDeudasAgrupadas(Object.entries(agrupadas || {}).map(([fecha, productos]) => ({
            fecha_compra: fecha,
            fecha_vencimiento: productos[0].fecha_vencimiento,
            deudas: productos,
            estado: productos[0].estado,
            puntoVenta: productos[0].id_punto_venta,
            detalles: productos[0].detalles
        })));
    }, [clientFile]);

    const sucursales = appData?.puntosVenta || [];
    const [deletingDebt, setDeletingDebt] = useState(false);

    const deleteDebt = (debtDate, branchId) => {
        if (hasDelivers) {
            const key = `delete_debt_${debtDate}_${branchId}`;
            notification.warning({
                key,
                message: "El cliente tiene entregas de dinero",
                description: (
                    <>
                        <p>Está seguro de querer eliminar esta deuda? Esto podría afectar las entregas de dinero guardadas.</p>
                        <p>Asegurece de eliminar antes cualquier entrega de dinero existente.</p>
                        <Space>
                            <Button
                                type="primary"
                                danger
                                onClick={() => {
                                    procedToDelete(debtDate, branchId);
                                    notification.destroy(key);
                                }}
                            >
                                Eliminar
                            </Button>
                            <Button
                                type="primary"
                                onClick={() => {
                                    notification.destroy(key);
                                }}
                            >
                                Cancelar
                            </Button>
                        </Space>
                    </>
                ),
                duration: 10,
                showProgress: true,
            });
        } else {
            procedToDelete(debtDate, branchId);
        }
    };

    const procedToDelete = async (debtDate, branchId) => {
        setDeletingDebt(true);
        try {
            const response = await fetch(`${baseUrl.api}/delete-debt/${debtDate}?clientId=${clientId}&branchId=${branchId}`, {
                method: "DELETE",
            });

            const data = await response.json();
            if (!response.ok) throw new Error(data.message);

            await getClientFile();
            message.success("Deuda eliminada", 2);
        } catch (error) {
            notification.error({
                message: "La deuda no pudo ser eliminada",
                description: error.message || "Error de conexión. Intente nuevamente.",
                duration: 4,
                showProgress: true,
                pauseOnHover: false,
            });
        } finally {
            setDeletingDebt(false);
        }
    };

    const [editingDebt, setEditingDebt] = useState(false);
    const [selectedDebt, setSelectedDebt] = useState(null);

    const handleEditDebt = (debtDate) => {
        const debtToEdit = deudasAgrupadas.find(debt => debt.fecha_compra === debtDate);
        setSelectedDebt(debtToEdit);
        setEditingDebt(true);
    };

    const columns = [
        {
            title: "Sucursal",
            width: 300,
            render: (_, record) => (
                <p>{sucursales.find(sucursal => sucursal.id === record.puntoVenta)?.punto_venta || "Sucursal no encontrada"}</p>
            ),
        },
        {
            title: "Fecha de compra",
            render: (_, record) => (
                <Space direction="vertical">
                    <strong>{dayjs(record.fecha_compra).format("DD/MM/YYYY")}</strong>
                </Space>
            ),
        },
        {
            title: "Vencimiento",
            render: (_, record) => (
                <Space>
                    <strong>{dayjs(record.fecha_vencimiento).format("DD/MM/YYYY")}</strong>
                    <Tag color={record.estado ? "green" : "red"}>{record.estado ? "Al día" : "Vencido"}</Tag>
                </Space>
            ),
        },
        {
            title: "Producto/s",
            width: 500,
            render: (_, record) => (
                <ul>
                    {record.deudas.map((debt, idx) => (
                        <li key={idx}>{debt.cantidad} {debt.producto} - {parseFloat(debt.precio).toLocaleString("es-AR", { style: "currency", currency: "ARS" })}</li>
                    ))}
                </ul>
            ),
        },
        {
            title: "Total",
            render: (_, record) => {
                const total = record.deudas.reduce((acc, curr) => {
                    return acc + parseFloat(curr.precio) * parseInt(curr.cantidad);
                }, 0);

                return <p>{total.toLocaleString("es-AR", { style: "currency", currency: "ARS" })}</p>;
            }
        },

        {
            title: "Detalles",
            width:350,
            render: (_, record) => (
                record.detalles !== "undefined" ? record.detalles : "N/A"
            ),
        },
        {
            render: (_, record) => (
                <Space direction="vertical">
                    <Button icon={<EditOutlined />} type="primary" onClick={() => handleEditDebt(record.fecha_compra)} />
                    <Popconfirm
                        title="¿Está seguro que desea eliminar esta deuda?"
                        okButtonProps={{ loading: deletingDebt }}
                        onConfirm={() => deleteDebt(record.fecha_compra, record.puntoVenta)}
                    >
                        <Button icon={<DeleteOutlined />} type="primary" danger />
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    return (
        <>
            <Card title="Lista de deudas" className="cards__debts" hoverable >
                {gettingFile ? (
                    <Skeleton active />
                ) : (
                    <Table
                        columns={columns}
                        dataSource={deudasAgrupadas}
                        rowKey={(row) => row.fecha_compra}
                        scroll={{ x: 600 }}
                        style={{overflowX:"scroll"}}
                    />
                )}
            </Card>
            {editingDebt && (
                <Modal
                    open={editingDebt}
                    onCancel={() => setEditingDebt(false)}
                    footer={null}
                    title="Editando deuda"
                >
                    <AddDebts
                        clientId={clientId}
                        getClientFile={getClientFile}
                        gettingFile={gettingFile}
                        selectedDebt={selectedDebt}
                        editingDebt={editingDebt}
                        closeModal={() => setEditingDebt(false)}
                    />
                </Modal>
            )}
        </>
    );
}

export default ViewDebts;
