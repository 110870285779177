import React, { useState } from 'react'
import { Button, Flex, message, Modal, notification, Space, Table } from 'antd'
import { useAppContext } from '../../context/AppContext'
import { DeleteOutlined, EditOutlined, FileAddOutlined } from '@ant-design/icons'
import { baseUrl } from '../../config'
import CreateBranch from './Crear Sucursales/CreateBranch'
function SucursalesWrapper() {
    const { appData, setAppData, setAuthUserInfo } = useAppContext()
    const puntosVenta = appData?.puntosVenta || []
    const [deleting, setDeleting] = useState(false)

    const handleDelete = async (id) => {
        const key = id
        notification.warning({
            key,
            message: "Atención",
            description: (
                <Flex wrap flex={"vertical"}>
                    <p>Los usuarios que tengan asignado esta sucursal tendran que volver a ser reasignados y no podran usar el sistema hasta que dicha acción se solucione.</p>
                    <Space>
                        <Button type='primary' onClick={() => notification.destroy(key)}>Cancelar</Button>
                        <Button type='primary' danger onClick={async () => {
                            await procedToDelete(id)
                            notification.destroy(key)
                        }} loading={deleting}>Eliminar</Button>
                    </Space>
                </Flex>
            ),
            duration: 10,
            showProgress: true,
            pauseOnHover: true
        })
    }

    const procedToDelete = async (id) => {
        setDeleting(true)
        try {
            const response = await fetch(`${baseUrl.api}/delete-branch/${id}`, {
                method: "DELETE"
            })
            const data = await response.json()

            if (response.status === 406) {
                notification.error({
                    message: "Error al eliminar la sucursal",
                    description: data.message,
                    duration: 5,
                    pauseOnHover: true,
                    showProgress: true
                })
                return;
            }

            if (!response.ok) throw new Error(data.message)
            message.success("Sucursal eliminada!")

            setAppData((prevData) => ({
                ...prevData,
                puntosVenta: prevData.puntosVenta.filter((sucursal) => sucursal.id !== id),
                usuarios: prevData.usuarios.map(usuario =>
                    usuario.id_punto_venta === id
                        ? { ...usuario, id_punto_venta: null }
                        : usuario
                )
            }))

            setAuthUserInfo((prevData) => ({
                ...prevData,
                id_punto_venta: prevData.id_punto_venta === id ? null : prevData.id_punto_venta
            }))

        } catch (error) {
            console.log(error)
            notification.error({
                message: "Error al eliminar la sucursal",
                duration: 4,
                description: error.message || "Error de red, verifique su conexión e intente nuevamente",
                pauseOnHover: false,
                showProgress: true
            });
        } finally {
            setDeleting(false)
        }
    }

    const [showForm, setShowForm] = useState(false)
    const [editing, setEditing] = useState(false)
    const [selectedBranch, setSelectedBranch] = useState({})

    const handleEdit = (branchId) => {
        setSelectedBranch(puntosVenta.find(pventa => pventa.id === branchId))
        setEditing(true)
        setShowForm(true)
    }

    const column = [
        {
            title: "Sucursal",
            render: (_, record) => (
                <p>{record.punto_venta}</p>
            )
        },
        {
            title: <Button type='primary' onClick={() => setShowForm(!showForm)} icon={<FileAddOutlined />}>Agregar sucursal</Button>,
            render: (_, record) => (
                <Space
                    direction='vertical'>
                    <Button icon={<EditOutlined />} type='primary' onClick={() => handleEdit(record.id)}></Button>
                    <Button icon={<DeleteOutlined />} type='primary' danger onClick={() => handleDelete(record.id)}></Button>
                </Space>
            )
        },
    ]


    return (
        <React.Fragment>
            <div className='container-wrapper'>
                <Table
                    rowKey={(row) => row.id}
                    pagination={{ pageSize: 15 }}
                    scroll={{ x: 600 }}
                    columns={column}
                    dataSource={puntosVenta}
                />
            </div>
            {showForm && (
                <Modal
                    open={showForm}
                    onCancel={() => {
                        setShowForm(false)
                        setEditing(false)
                    }}
                    footer={[]}
                    title={editing ? "Editando sucursal" : "Agregando sucursal"}
                >
                    <CreateBranch closeModal={() => setShowForm(false)} editing={editing} selectedBranch={selectedBranch} />
                </Modal>
            )}
        </React.Fragment>
    )
}

export default SucursalesWrapper