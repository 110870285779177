import { SignInButton } from '@clerk/clerk-react'
import React, { useEffect } from 'react'
import "./login.css"

import GoogleIcon from '../../assets/GoogleIcon';
import { useAppContext } from '../../context/AppContext';
import { useNavigate } from 'react-router-dom';

function Login() {
   const {authUserInfo} = useAppContext()
    const navigate = useNavigate()
   useEffect(()=>{
    if (authUserInfo && authUserInfo?.administrador) {
        navigate("/dashboard")
    }
   },[authUserInfo])

    return (
        <div className='container-wrapper'>
            <div className="login__wrapper">
                <div className="login__form">
                    <h1 className='title'>Bienvenido a Gestión Corriente</h1>
                    <SignInButton className="login__button"><div style={{
                        display: "flex",
                        gap: ".5rem",
                        width: "min-content",
                    }}><GoogleIcon />Iniciar sesión</div></SignInButton>
                </div>

            </div>
        </div>
    )
}

export default Login