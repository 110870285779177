import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import Login from './componentes/Login/Login';
import Dashboard from './componentes/Dashboard/Dashboard';
import { SignedIn } from '@clerk/clerk-react';
import ClientsWrapper from './componentes/Clientes/ClientsWrapper';
import ExpirationsWrapper from './componentes/Vencimientos/ExpirationsWrapper';
function App() {
  return (
    <Routes>
      <Route path='/' element={<Login />} />
      <Route path='/dashboard' element={<SignedIn><Dashboard/></SignedIn>}/>
      <Route path='/clientes/*' element={<SignedIn><ClientsWrapper/></SignedIn>}/>
      <Route path='/expirations' element={<SignedIn><ExpirationsWrapper/></SignedIn>}/>
      <Route path='*' element={<Navigate to="/"/>}/>
    </Routes>
  );
}

export default App;
