import React, { useState } from 'react';
import "./navbar.css";
import { SignOutButton } from '@clerk/clerk-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppContext } from '../../context/AppContext';
import { Space, Spin } from 'antd';
import MenuIcon from '../../assets/MenuIcon';
import CloseMenuIcon from "../../assets/CloseMenuIcon";

function Navbar() {
    const navigate = useNavigate();
    const location = useLocation();
    const { authUserInfo, isAdmin, activePanel, setActivePanel } = useAppContext();
    const [loading, setLoading] = useState(false);

    const togglePanel = () => {
        setActivePanel(!activePanel);
    };

    const handleSignOut = () => {
        setLoading(true);
    };

    const shortName = (text) => {
        if (!text) return text;
        const words = text.split(" ")
        if (words.length > 2) {
            return words.slice(0, 2).join(" ")
        } else {
            return words.join(" ")
        }

    }

    return (
        <header className='navbar'>
            <nav className='nav-pc'>
                <div className="user__info">
                    <picture className="user__image">
                        <img src={authUserInfo.userimage} alt="" />
                    </picture>
                    <div className="user__name">
                        <Space>
                            {shortName(authUserInfo.userfullname)}
                            <SignOutButton
                                className="logout__button"
                                onClick={handleSignOut}
                                onSignOutComplete={() => setLoading(false)}
                            >
                                {loading ? <Spin size='small' /> : "Cerrar sesión"}
                            </SignOutButton>
                        </Space>
                    </div>
                </div>
                <ul className={'nav-pc__links'}>
                    {isAdmin && (
                        <li
                            className={location.pathname === "/dashboard" ? "nav-pc__li active" : "nav-pc__li"}
                            onClick={() => navigate("/dashboard")}
                        >
                            Inicio
                        </li>
                    )}
                    
                    <li
                        className={location.pathname.includes("clientes") ? 'nav-pc__li active' : 'nav-pc__li'}
                        onClick={() => navigate("/clientes")}
                    >
                        Clientes
                    </li>

                    <li className={location.pathname.includes("expirations") ? 'nav-pc__li active' : 'nav-pc__li'} onClick={() => navigate("/expirations")}>Vencimientos</li>

                    <li className={location.pathname.includes("settings") ? 'nav-pc__li active' : 'nav-pc__li'} onClick={() => navigate("/settings")}>Ajustes</li>
                </ul>
                <div className='menuIcon' style={{ margin: ".5rem", cursor: "pointer" }} onClick={togglePanel}>{activePanel ? <CloseMenuIcon /> : <MenuIcon />}</div>
                <ul className={activePanel ? 'nav-phone__links active' : 'nav-phone__links'}>
                    <h1>{shortName(authUserInfo.userfullname)}</h1>
                    {isAdmin && (
                        <li
                            className={location.pathname === "/dashboard" ? "nav-pc__li active" : "nav-pc__li"}
                            onClick={() => {
                                navigate("/dashboard");
                                togglePanel();
                            }}
                        >
                            Inicio
                        </li>
                    )}
                    <li
                        className={location.pathname.includes("clientes") ? 'nav-pc__li active' : 'nav-pc__li not-active'}
                        onClick={() => {
                            navigate("/clientes");
                            togglePanel();
                        }}
                    >
                        Clientes
                    </li>

                    <li className={location.pathname.includes("expirations") ? 'nav-pc__li active' : 'nav-pc__li'} onClick={() => {
                        navigate("/expirations");
                        togglePanel();
                    }}>Vencimientos</li>

                    <li className={location.pathname.includes("settings") ? 'nav-pc__li active' : 'nav-pc__li'} onClick={() => {
                        navigate("/settings");
                        togglePanel();
                    }}>Ajustes</li>
                </ul>
            </nav>
        </header>
    );
}

export default Navbar;
