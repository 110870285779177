import React, { useState } from 'react'
import OpenEye from "../../assets/OpenEye"
import CloseEye from "../../assets/CloseEye"
import { Button, Divider, Popconfirm, Select, Space, Spin } from 'antd'
import { useAppContext } from '../../context/AppContext'
import { useLocation, useParams } from 'react-router-dom'
import { ReloadOutlined } from '@ant-design/icons'
import "./sidebar.css"
const { Option } = Select

function Sidebar({ 
  references,
  componentTitle,
  onLinkSelect,
  componentSubtitle,
  RetryOperation,
  totalFile,
  gettingFile,
  getClientFile,
  cancelDebts,
  canCancel
}) {
  const {
    appData,
    authUserInfo,
    assignBusiness,
    setAuthUserInfo,
    resizeSidebar,
    setResizeSidebar,
    activePanel
  } = useAppContext()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const branchIdFromQuery = queryParams.get("branchId");

  const [branchId, setBranchId] = useState(branchIdFromQuery)

  const currentPath = location.pathname
  const sucursales = appData.puntosVenta || []

  const onChangeSucursales = async (branchId, userid) => {
    setBranchId("")
    const result = assignBusiness(branchId, userid);
    if (result) {
      setAuthUserInfo((prevData) => ({
        ...prevData,
        id_punto_venta: branchId
      }))
      await getClientFile(branchId)
    }
  };

  const hasAssignedBranch = Boolean(authUserInfo.id_punto_venta)
  
  return (
    <div className={`sidebar-container ${resizeSidebar ? 'expanded' : 'reduced'}`}>
      <nav className="sidebar__wrapper">
        <h1 className='title'>{componentTitle}</h1>

        {currentPath.includes("/clientes/cuentas-corrientes/") && (
          <Space direction='vertical' className="branch-selection">
            <p><strong>Sucursal: </strong>{sucursales.find(sucursal => sucursal.id === branchId || authUserInfo.id_punto_venta)?.punto_venta ?? ""}</p>
            <Select 
              onChange={(val) => onChangeSucursales(val, authUserInfo.userid)} 
              style={{ minWidth: "120px" }} 
              value={branchId || authUserInfo.id_punto_venta}
            >
              {sucursales && sucursales.map((branch, index) => (
                <Option key={index} value={branch.id}>{branch.punto_venta}</Option>
              ))}
            </Select>
            <Button onClick={() => RetryOperation()}>Refrescar <ReloadOutlined /></Button>
            {canCancel && (
              <Popconfirm
                title="¿Seguro que desea cancelar?"
                description="Esta acción eliminará deudas y entregas y se guardarán en el historial del cliente."
                onConfirm={() => cancelDebts(authUserInfo.id_punto_venta)}
              >
                <Button type='primary' danger>Cancelar deudas</Button>
              </Popconfirm>
            )}
            {!hasAssignedBranch && <p className="error-message">Seleccione una sucursal de la lista</p>}
          </Space>
        )}

        <Divider />
        <h3 className="subtitle">{componentSubtitle}</h3>
        {currentPath.includes("cuentas-corrientes") && (
          <p className="total-balance">
            <strong>Saldo total: </strong>
            {gettingFile ? <Spin size='small' /> : parseInt(totalFile).toLocaleString("es-AR", { style: "currency", currency: "ARS" })}
          </p>
        )}
        <ul className='sidebar__links'>
          {references && references.map((refer, idx) => (
            <li
              onClick={() => onLinkSelect(refer.key)}
              key={idx}
              className="sidebar-item"
            >
              <span className="icon">{refer.icon}</span>
              <span className="item-name">{refer.name}</span>
            </li>
          ))}
        </ul>
      </nav>
      <div className={`toggle-button`} onClick={() => setResizeSidebar(!resizeSidebar)}>
        <span>{resizeSidebar ? <OpenEye /> : <CloseEye />}</span>
      </div>
    </div>
  );
}

export default Sidebar