import { useAuth, useUser } from "@clerk/clerk-react";
import { message, notification } from "antd";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { baseUrl } from "../config";
const AppContext = createContext();
export const useAppContext = () => {
    const ctx = useContext(AppContext);
    if (!ctx) {
        throw new Error("useAppContext must be used within a AppProvider");
    }
    return ctx
};

export const AppContextProvider = ({ children }) => {
    const { signOut } = useAuth();
    const { user } = useUser()
    const [authUserInfo, setAuthUserInfo] = useState({})
    const [isAdmin, setIsAdmin] = useState(false)
    const [editingClient, setEditingClient] = useState(false)
    const [selectedClient, setSelectedClient] = useState(null)
    const [resizeSidebar, setResizeSidebar] = useState(false)
    const [activePanel, setActivePanel] = useState(false);

    const verifiyUser = async () => {
        const hiddenMessage = message.loading("Un segundo...", 0)
        const dataToSend = {
            nombre_completo: user.fullName,
            usuario: user.username,
            correo: user.emailAddresses[0].emailAddress,
            avatar: user.imageUrl,
            id: user.id
        }
        try {
            const response = await fetch(`${baseUrl.api}/verify-user`, {
                method: "POST",
                body: JSON.stringify(dataToSend)
            });
            const data = await response.json()
            if (!response.ok) throw new Error(data.message || "Error al verificar la sesión")
            if (response.status === 203) {
                notification.warning({
                    message: data.message,
                    duration: 3,
                    pauseOnHover: true
                })
                setTimeout(() => {
                    signOut()
                }, 3000)
            }
            if (response.status === 200) {
                notification.success({
                    message: data.message,
                    duration: 3,
                    pauseOnHover: true
                })
                setAuthUserInfo(data.allowed_user[0])
                setIsAdmin(true)
            }

            if (response.status === 400) {
                notification.error({
                    message: "Error al iniciar sesión",
                    description: data.message,
                    duration: 3,
                    pauseOnHover: true
                })
                setTimeout(() => {
                    signOut()
                }, 3000)
            }
        } catch (error) {
            console.log(error)
            notification.error({
                message: "Error al autenticar la sesión",
                description: "Error de red o de servidor, aguarde unos segundos e intente nuevamente"
            });

            setTimeout(async () => {
                await signOut()
            }, 3000);
        } finally {
            hiddenMessage()
        }
    }

    const [appData, setAppData] = useState([])
    const getNecessaryData = async () => {
        const hiddenMessage = message.loading("Un segundo...", 0)
        try {
            const response = await fetch(`${baseUrl.api}/get-all-data/${authUserInfo.userid}`)
            const data = await response.json()
            if (!response.ok) throw new Error(data.message)
            setAppData(data)
        } catch (error) {
            console.log(error)
            notification.error({
                message: "Error al cargar la aplicación",
                description: error.message || "Error de conexión, aguarde unos segundos y recargue esta sección",
                duration: 4,
                pauseOnHover: false,
                showProgress: true
            })
        } finally {
            hiddenMessage()
        }
    };

    const assignBusiness = async (businessId, userid) => {
        const hiddenMessage = message.loading("Aguarde...", 0);
        try {
            const response = await fetch(`${baseUrl.api}/assign-business/${businessId}`, {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ userId: userid })
            });

            const data = await response.json();
            if (!response.ok) throw new Error(data.message);

            const updatedUsuarios = appData.usuarios.map(usuario =>
                usuario.userid === userid
                    ? { ...usuario, id_punto_venta: businessId }
                    : usuario
            );

            setAppData(prevAppData => ({
                ...prevAppData,
                usuarios: updatedUsuarios
            }));

            notification.success({
                message: "Sucursal asignada!",
                duration: 2,
                pauseOnHover: false
            });

            return true
        } catch (error) {
            console.log(error);
            notification.error({
                message: "Error al asignar la sucursal",
                description: error.message || "Error de conexión, espere unos segundos e intente nuevamente",
                duration: 3
            });
            return false
        } finally {
            hiddenMessage();
        }
    };


    const changeAccessUser = async (userid) => {
        const hiddenMessage = message.loading("Aguarde...", 0)
        try {
            const response = await fetch(`${baseUrl.api}/change-access-user/${userid}`, {
                method: "PUT"
            });

            const data = await response.json();
            if (!response.ok) throw new Error(data.message)
            const updatedUser = appData.usuarios.map(usuario =>
                usuario.userid === userid
                    ? { ...usuario, autorizado: !usuario.autorizado }
                    : usuario
            );

            setAppData(prevData => ({
                ...prevData,
                usuarios: updatedUser
            }));

            notification.success({
                message: "Acceso actualizado",
                duration: 2,
                pauseOnHover: false
            });
        } catch (error) {
            console.log(error)
            notification.error({
                message: "Error al cambiar el acceso.",
                description: error.message ?? "Error de red, verifique su conexión e intente nuevamente."
            })
        } finally {
            hiddenMessage()
        }
    };

    const saveClient = async (newClient) => {
        if (!newClient) {
            message.error("Algunos campos no estan completos.")
            return false
        }
        const hiddenMessage = message.loading("Aguarde...", 0)

        try {
            const response = await fetch(`${baseUrl.api}/save-client`, {
                method: "POST",

                body: JSON.stringify(newClient)
            });

            const data = await response.json()

            if (!response.ok) throw new Error(data.message)


            setAppData((prevData) => ({
                ...prevData,
                clientes: Array.isArray(prevData.clientes) ? [...prevData.clientes, newClient] : [newClient]
            }));

            notification.success({
                message: "Cliente guardado!",
                duration: 2,
                showProgress: true,
                pauseOnHover: false
            });

            return true
        } catch (error) {
            console.log(error)
            notification.error({
                message: "No fue posible guardar el cliente",
                description: error.message ?? "Error de conexión, espere unos segundos e intente nuevamente.",
                duration: 2,
                pauseOnHover: false,
                showProgress: true
            })

            return false
        } finally {
            hiddenMessage()
        }

    }

    const editClient = async (newClient, clientId) => {
        const hiddenMessage = message.loading("Aguarde...", 0)
        if (!newClient && !newClient.clientId) {
            message.error("Algunos campos no estan completos.")
            return false
        }

        try {
            const response = await fetch(`${baseUrl.api}/edit-client/${clientId}`, {
                method: "PUT",
                body: JSON.stringify(newClient)
            });

            const data = await response.json()
            if (!response.ok) throw new Error(data.message)

            const updatedClient = appData.clientes.map(cliente =>
                cliente.id === clientId
                    ? { ...cliente, ...newClient }
                    : cliente
            )

            setAppData((prevData) => ({
                ...prevData,
                clientes: updatedClient
            }))

            notification.success({
                message: "Datos del cliente actualizado!",
                duration: 2,
                showProgress: true,
                pauseOnHover: false
            });

            return true
        } catch (error) {
            console.log(error)
            notification.error({
                message: "No fue posible actualizar el cliente",
                description: error.message ?? "Error de conexión, espere unos segundos e intente nuevamente.",
                duration: 4,
                pauseOnHover: false,
                showProgress: true
            })

            return false
        } finally {
            hiddenMessage()
        }

    };

    const saveDebt = async (arrayDebts, clientId) => {

        const hiddenMessage = message.loading("Aguarde...", 0)
        try {
            const response = await fetch(`${baseUrl.api}/save-debt/${clientId}?branchId=${authUserInfo.id_punto_venta}`, {
                method: "POST",

                body: arrayDebts
            })

            const data = await response.json()

            if (!response.ok) throw new Error(data.message)
            notification.success({
                message: "Producto/s guardado/s",
                duration: 2,
                showProgress: true,
                pauseOnHover: false
            })
            return true
        } catch (error) {
            console.log(error)
            notification.error({
                message: "Error al guardar la deuda",
                description: error.message || "Error de conexión, espere unos segundos e intente nuevamente.",
                duration: 4,
                showProgress: true,
                pauseOnHover: false
            })

            return false
        } finally {
            hiddenMessage()
        }
    }

    const saveDeliver = async (deliverData, clientId) => {
        const hiddenMessage = message.loading("Aguarde...", 0)
        try {
            const response = await fetch(`${baseUrl.api}/save-client-deliver/${clientId}?branchId=${authUserInfo.id_punto_venta}`, {
                method: "POST",
                body: deliverData
            });
            const data = await response.json()
            if (!response.ok) throw new Error(data.message)
            notification.success({
                message: "Entrega guardada!",
                duration: 2,
                pauseOnHover: false,
                showProgress: true
            });
            return true
        } catch (error) {
            console.log(error)
            notification.error({
                message: "Error al guardar la entrega.",
                description: error.message || "Error de conexión, verifique su internet e intente nuevamente.",
                duration: 4,
                showProgress: true,
                pauseOnHover: false
            })
            return false
        } finally {
            hiddenMessage()
        }
    }

    const editDeliver = async (deliverData, deliverId) => {
        const hiddenMessage = message.loading("Aguarde...", 0)
        try {
            const response = await fetch(`${baseUrl.api}/update-deliver?deliverId=${deliverId}`, {
                method: "PUT",
                body: deliverData
            })

            const data = await response.json()

            if (!response.ok) throw new Error(data.message)
            message.success("Entrega actualizada!", 3)
            return true
        } catch (error) {
            console.log(error)
            notification.error({
                message: "No se pudo actualizar la entrega.",
                description: error.message || "Error de conexión, verifique su internet e intente nuevamente.",
                showProgress: true,
                pauseOnHover: false,
                duration: 4
            })
            return false
        } finally {
            hiddenMessage()
        }
    }

    const editDebt = async (debtData, clientId, debtDate) => {
        const branchId = authUserInfo?.id_punto_venta
        const hiddenMessage = message.loading("Aguarde...", 0)

        try {
            const response = await fetch(`${baseUrl.api}/update-debt/${clientId}?branchId=${branchId}&oldDebtDate=${debtDate}`, {
                method: "PUT",
                body: debtData
            });

            const data = await response.json()

            if (!response.ok) throw new Error(data.message)
            message.success("Deuda actualizada!", 3)
            return true
        } catch (error) {
            console.log(error)
            notification.error({
                message: "No se pudo actualizar la Deuda.",
                description: error.message || "Error de conexión, verifique su internet e intente nuevamente.",
                showProgress: true,
                pauseOnHover: false,
                duration: 4
            })
            return false
        } finally {
            hiddenMessage()
        }
    }

    useEffect(() => {
        if (user) {
            verifiyUser()
        }
    }, [user])

    const alreadyLoad = useRef(false)
    useEffect(() => {
        if (authUserInfo && authUserInfo?.userid && !alreadyLoad.current) {
            alreadyLoad.current = true
            getNecessaryData()
        }
    }, [authUserInfo])

    const [clientId, setClientId] = useState(null)
    const [clientFile, setClientFile] = useState({});
    const [retryFetch, setRetryFetch] = useState(false);
    const [totalDebts, setTotalDebts] = useState(0);
    const [totalDelivers, setTotalDelivers] = useState(0);
    const [subTotalFile, setSubTotalFile] = useState(0);
    const [gettingFile, setGettingFile] = useState(false);


    const getClientFile = async (branchId = "", clientIdParams = "") => {
        setGettingFile(true);

        try {
            const response = await fetch(`${baseUrl.api}/get-client-file/${clientIdParams || clientId}?branchId=${branchId || authUserInfo.id_punto_venta}`);
            const data = await response.json();
            if (response.status === 404) {
                setRetryFetch(true);
                setTotalDelivers(0);
                setTotalDebts(0);
                setSubTotalFile(0);
                setClientFile({});
                setTimeout(() => {
                    notification.info({
                        message: data.message,
                        duration: 2,
                        showProgress: true,
                        pauseOnHover: true,
                    });
                }, 1000);
                setRetryFetch(false);
                return;
            }
            if (response.status !== 200) throw new Error(data.message);

            setTimeout(() => {
                notification.success({
                    message: 'Fichero obtenido!',
                    duration: 2,
                    pauseOnHover: false,
                    showProgress: true,
                });
            }, 1000);
            setClientFile(data);
            setRetryFetch(false);
        } catch (error) {
            console.log(error);
            setRetryFetch(true);
            setTotalDelivers(0);
            setTotalDebts(0);
            setSubTotalFile(0);
            setClientFile({});
            notification.error({
                message: 'Error al obtener el fichero del cliente',
                description: error.message || 'Error de conexión, verifique su internet e intente nuevamente.',
                duration: 4,
                showProgress: true,
                pauseOnHover: false,
            });
        } finally {
            setTimeout(() => {
                setGettingFile(false);
            }, 1000);
        }
    };

    const [monthlyExp, setMonthlyExp] = useState([])
    const [expiredFiles, setExpiredFiles] = useState([])
    const getExpirations = async() => {
        try {
            const response = await fetch(`${baseUrl.api}/get-expirations`)
            let data = {}
            
            if(!response.ok) throw new Error("Error al procesar la solicitud.")
            data = await response.json()
            message.success(`${data.msg}`,2)
            setExpiredFiles(data.expiredFiles)
            setMonthlyExp(data.monthlyExp)
        } catch (error) {
            console.log(error)
            notification.error({
                message: "Error al obtener los vencimientos",
                description: error.message || "Error de red, verifique su conexión e intente nuevamente.",
                duration: 4,
                pauseOnHover: false,
                showProgress: true
            })
        }
    };


    return (
        <AppContext.Provider value={{
            isAdmin, authUserInfo, setAuthUserInfo, getNecessaryData, appData,
            assignBusiness, changeAccessUser, saveClient,
            setEditingClient, editingClient, setSelectedClient, selectedClient,
            editClient, saveDebt, saveDeliver,
            editDeliver, editDebt,
            activePanel, setActivePanel, resizeSidebar, setResizeSidebar,
            clientFile, retryFetch, totalDebts, totalDelivers, 
            subTotalFile, gettingFile, getClientFile,getExpirations,
            clientId, setClientId, setTotalDebts, setTotalDelivers, 
            setSubTotalFile, setAppData, monthlyExp, expiredFiles
        }}
        >
            {children}
        </AppContext.Provider>
    )
}