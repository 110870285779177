import { Card, DatePicker, Form, message } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import React, { useEffect, useState } from 'react'
import "./addDebts.css"
import dayjs from 'dayjs'
import { useAppContext } from '../../../context/AppContext'
function AddDebts({clientId, getClientFile, gettingFile, selectedDebt, editingDebt, closeModal}) {

    const [form] = Form.useForm()
    const [debtDate, setDebtDate] = useState(dayjs())
    const { saveDebt, editDebt, authUserInfo } = useAppContext()
    const onFinish = async (values) => {
        const { debtName } = values
        const products = debtName.trim().split("\n")
        const productList = products.map((prod) => {
            const [quantity, ...rest] = prod.trim().split(" ")
            const price = rest.pop()
            const name = rest.join(" ")

            if (isNaN(parseInt(quantity))) {
                message.error("La cantidad de uno de los productos no es válida");
                return null;
            }
            
            if (isNaN(parseFloat(price))) {
                message.error("El precio de uno de los productos no es válido");
                return null;
            }

            if(!name){
                message.error("El producto introducido no es válido")
                return null
            }

            return {
                quantity: parseInt(quantity),
                name,
                price: parseFloat(price),
            };
        })
        const formData = new FormData()

        const validProducts = productList.filter(Boolean);

        formData.append("arrayProducts", JSON.stringify(validProducts))
        formData.append("buyDate", dayjs(debtDate))

        if(validProducts.length === productList.length){
            const result = editingDebt ? await editDebt(formData, clientId, selectedDebt.fecha_compra) : await saveDebt(formData, clientId)

            if(result){
                form.resetFields()
                await getClientFile()
            }
            if(editingDebt) closeModal()
        }
    }

    const onChangeDate = (date) => {
        setDebtDate(date)
    }
    useEffect(()=>{
        if (editingDebt && selectedDebt) {
            const formatProducts = selectedDebt.deudas.map((debt,idx)=>{
                return `${debt.cantidad} ${debt.producto} ${debt.precio}`
            }).join("\n")
            form.setFieldsValue({
                debtName: formatProducts,
                debtDetails: selectedDebt.detalles ?? ""
            })
            setDebtDate(dayjs(selectedDebt.fecha_compra))
        }
    },[editingDebt, selectedDebt])

    const hasAssignedBranch = Boolean(authUserInfo.id_punto_venta)
    return (
        <Card title={editingDebt ? "" : "Añadir deudas"}
            className={editingDebt ? "" : 'cards__debts'}
            
        >
            <Form
                name={"debtsForm"}
                onFinish={onFinish}
                form={form}
                layout='vertical'
                initialValues={{
                    debtDate: debtDate
                }}
            >
                <Form.Item
                    name={"debtName"}
                    label="Productos:"
                    rules={[
                        
                        {validator: (_,value) => {
                            if (value.trim().length === 0) return Promise.reject("Este campo no puede estar vacio")
                            return Promise.resolve()
                        }}
                    ]}
                >
                    <TextArea
                        style={{ 
                            resize: 'none'
                            , width: '100%'
                            , height: '250px'
                            , fontSize: "1rem"
                         }}
                        placeholder="Escribe aquí los productos..."
                    />
                </Form.Item>
                <Form.Item
                    name={"debtDate"}
                    label="Fecha de compra"
                    id='debtDate'
                    rules={[
                        
                        {validator: () => {
                            if(!debtDate) return Promise.reject("Este campo no puede estar vacío")
                            return Promise.resolve()
                        }}
                    ]}
                >
                    <DatePicker id='debtDate' onChange={onChangeDate} />
                </Form.Item>
                <Form.Item>
                    <button type='submit' style={{backgroundColor: !hasAssignedBranch && "grey", cursor: !hasAssignedBranch ? "not-allowed": ""}} className='btn__save-debt' disabled={gettingFile || !hasAssignedBranch}>{editingDebt ? "Actualizar" : "Guardar"} deuda</button>
                    {hasAssignedBranch
                    ? ""
                    : hasAssignedBranch ? null : <p style={{color: "red", marginLeft: ".5rem"}}>Seleccione una sucursal de la lista en el panel izquierdo</p>
                    }
                </Form.Item>
            </Form>
        </Card>
    )
}

export default AddDebts